// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_main__thu82 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: black;
    overflow-x: hidden;
}

.Main_content__baWrf {
    width: 100%;
    padding: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Main/Main.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".main {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    background: black;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.content {\r\n    width: 100%;\r\n    padding: 1rem 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Main_main__thu82`,
	"content": `Main_content__baWrf`
};
export default ___CSS_LOADER_EXPORT___;
