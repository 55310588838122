// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_select__fhkh4 {
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    -webkit-user-select: none;
            user-select: none;
}

.Select_selected__vd3iy {
    background: #d8d8d8;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Select_select__fhkh4 *:hover {
    cursor: pointer;
}

.Select_select__fhkh4.Select_hidden__na6gw .Select_selected__vd3iy {
    background: #00ff87;
}

.Select_selected__vd3iy .Select_arrow__05XMs {
    margin-top: 9px;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

.Select_options__1r83i {
    position: absolute;
    background: #00ff87;
    width: 100%;
    z-index: 1;
}

.Select_select__fhkh4.Select_hidden__na6gw .Select_options__1r83i {
    display: none;
}

.Select_options__1r83i div {
    padding: 8px 16px;
    width: calc(100% - 32px);
    border-top: 1px solid rgba(0,0,0,0.4);
}

.Select_options__1r83i div:last-of-type {
    border-bottom: 1px solid rgba(0,0,0,0.4);
}

.Select_options__1r83i div:hover {
    background: #02dd77;
}

.Select_options__1r83i div.Select_hidden__na6gw {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Select/Select.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,sDAAsD;AAC1D;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,qCAAqC;AACzC;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".select {\r\n    position: relative;\r\n    font-family: 'Roboto', sans-serif;\r\n    font-size: 16px;\r\n    font-weight: 300;\r\n    user-select: none;\r\n}\r\n\r\n.selected {\r\n    background: #d8d8d8;\r\n    padding: 8px 16px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.select *:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n.select.hidden .selected {\r\n    background: #00ff87;\r\n}\r\n\r\n.selected .arrow {\r\n    margin-top: 9px;\r\n    border: 6px solid transparent;\r\n    border-color: #fff transparent transparent transparent;\r\n}\r\n\r\n.options {\r\n    position: absolute;\r\n    background: #00ff87;\r\n    width: 100%;\r\n    z-index: 1;\r\n}\r\n\r\n.select.hidden .options {\r\n    display: none;\r\n}\r\n\r\n.options div {\r\n    padding: 8px 16px;\r\n    width: calc(100% - 32px);\r\n    border-top: 1px solid rgba(0,0,0,0.4);\r\n}\r\n\r\n.options div:last-of-type {\r\n    border-bottom: 1px solid rgba(0,0,0,0.4);\r\n}\r\n\r\n.options div:hover {\r\n    background: #02dd77;\r\n}\r\n\r\n.options div.hidden {\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `Select_select__fhkh4`,
	"selected": `Select_selected__vd3iy`,
	"hidden": `Select_hidden__na6gw`,
	"arrow": `Select_arrow__05XMs`,
	"options": `Select_options__1r83i`
};
export default ___CSS_LOADER_EXPORT___;
