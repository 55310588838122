import { useState, useEffect, useRef } from "react";
import classes from "./Select.module.css";

const Select = (props) => {
  const dropdown = useRef(null);
  const [useDefault, setUseDefault] = useState(props.options);
  const [isOpen, setIsOpen] = useState(false);

  const [activeValue, setActiveValue] = useState(
    props.options.find((x) => x.default) || {
      label: props.placeholder,
      value: "placeholder",
    }
  );
  useEffect(() => {
    if (useDefault) {
      setActiveValue(
        props.options.find((x) => x.default) || {
          label: props.placeholder,
          value: "placeholder",
        }
      );
    }
  }, [props.options, props.placeholder, useDefault]);

  const options = props.options.map((x) => {
    let hidden = activeValue.value === x.value ? classes.hidden : "";
    return (
      <div key={x.value} value={x.value} className={hidden}>
        {x.label}
      </div>
    );
  });

  const selectHandler = (e) => {
    let selected = e.target.closest(`.${classes.selected}`);
    let option = e.target.closest(`.${classes.options} div`);

    if (selected) {
      setIsOpen((prev) => !prev);
    } else if (option) {
      setUseDefault(false);
      option.closest(`.${classes.select}`).classList.toggle(classes.hidden);
      setActiveValue({
        label: option.innerText,
        value: option.getAttribute("value"),
      });
      props.onChange(option.getAttribute("value"));
    }
  };

  useEffect(() => {
    const outsideClickHandler = (e) => {
      if (dropdown.current && !dropdown.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", outsideClickHandler);
    return () => {
      document.removeEventListener("click", outsideClickHandler);
    };
  }, [dropdown]);

  return (
    <div
      className={`${classes.select} ${!isOpen && classes.hidden}`}
      ref={dropdown}
      id={props.id}
      style={props.style}
      onClick={selectHandler}
    >
      <div className={classes.selected} value={activeValue.value}>
        <label>{activeValue.label}</label>
        <div className={classes.arrow}></div>
      </div>
      <div className={classes.options}>{options}</div>
    </div>
  );
};

export default Select;
