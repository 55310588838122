import classes from "./Footer.module.css";

const Footer = (props) => {
  return <div className={classes.footer}>
    <nav className={classes.nav}>
        <div className={classes.navItem}>
            Start
        </div>
        <div className={classes.navItem}>
            Points
        </div>
        <div className={classes.navItem}>
            Stats
        </div>
    </nav>
  </div>;
};

export default Footer;
