import classes from "./Main.module.css";

const Main = (props) => {
  return (
    <div className={classes.main}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

export default Main;
