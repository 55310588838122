// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__Yz85Q {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
}

.Header_logo__XQSdj {
    width: 120px;
    height: auto;
    padding: 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".header {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background: #000;\r\n}\r\n\r\n.logo {\r\n    width: 120px;\r\n    height: auto;\r\n    padding: 8px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__Yz85Q`,
	"logo": `Header_logo__XQSdj`
};
export default ___CSS_LOADER_EXPORT___;
