import React, { useState } from "react";
import Select from "../UI/Select/Select";
import classes from "./Team.module.css";
import TeamPlayerCard from "./TeamPlayerCard";
import MainLoading from "../Layout/Main/MainLoading";
import { getEntries } from "../../includes/users";

import refreshArrow from "../../assets/img/refresh-arrow.png";

const Team = (props) => {
  const getCards = (role) => {
    return props.players
      .filter((x) => {
        if (role === "GK" && x.element_type === 1 && x.position === 1) {
          return x;
        } else if (
          role === "DEF" &&
          x.element_type === 2 &&
          x.position >= 2 &&
          x.position <= 6
        ) {
          return x;
        } else if (
          role === "MID" &&
          x.element_type === 3 &&
          x.position >= 4 &&
          x.position <= 10
        ) {
          return x;
        } else if (
          role === "FWD" &&
          x.element_type === 4 &&
          x.position >= 9 &&
          x.position <= 11
        ) {
          return x;
        } else if (role === "BENCH" && x.position >= 12 && x.position <= 15) {
          return x;
        }
      })
      .map((x) => {
        return <TeamPlayerCard key={x.id} player={x} />;
      });
  };

  const getTotalPoints = () => {
    let points = 0;
    props.players.map((x) => {
      if (x.multiplier) {
        points += x.stats.stats.total_points * x.multiplier;
      }
    });
    return points;
  };

  const gameWeekOptions = [];
  for (let i = 1; i <= props.playedGameWeeks; i++) {
    gameWeekOptions.unshift({
      label: `Game Week ${i}`,
      value: i.toString(),
      default: props.gameWeek === i,
    });
  }

  const entryFilter = getEntries().map((x) => {
    return {
      ...x,
      default: props.entry.toString() === x.value,
    };
  });

  const selectGameWeekHandler = (value) => {
    props.onSelectGameWeek(value);
  };

  const selectEntryHandler = (value) => {
    props.onSelectEntry(value);
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 515);
  window.addEventListener("resize", () => {
    if (
      (window.innerWidth < 515 && !isMobileView) ||
      (window.innerWidth >= 515 && isMobileView)
    ) {
      setIsMobileView(window.innerWidth < 515);
    }
  });

  const filterRow = (
    <div className={`${classes.filterRow}`}>
      <Select
        id="entryFilter"
        onChange={selectEntryHandler}
        options={entryFilter}
        style={{
          width: props.width || "100%",
          maxWidth: isMobileView ? "90%" : "220px",
          margin: "2px 10px",
          fontSize: "14px",
          fontWeight: 300
        }}
      />
      <Select
        id="gameWeekFilter"
        onChange={selectGameWeekHandler}
        placeholder="Select game week:"
        options={gameWeekOptions}
        style={{
          width: props.width || "100%",
          maxWidth: isMobileView ? "90%" : "220px",
          margin: "2px 10px",
          fontSize: "14px",
          fontWeight: 300
        }}
      />
    </div>
  );

  let pitch;
  if (props.loading) {
    pitch = (
      <div className={classes.pitch}>{props.loading && <MainLoading />}</div>
    );
  } else {
    pitch = (
      <div className={classes.pitch}>
        <div className={classes.row}>
          {props.players.length > 0 && getCards("GK")}
        </div>
        <div className={classes.row}>
          {props.players.length > 0 && getCards("DEF")}
        </div>
        <div className={classes.row}>
          {props.players.length > 0 && getCards("MID")}
        </div>
        <div className={classes.row}>
          {props.players.length > 0 && getCards("FWD")}
        </div>
        <div className={`${classes.row} ${classes.rowBench}`}>
          {props.players.length > 0 && getCards("BENCH")}
        </div>
      </div>
    );
  }

  const refreshIcon = props.loading ? <div className={classes.refresh}></div> : <img alt="refresh" className={classes.refresh} src={refreshArrow} onClick={props.onRefresh} />;

  return (
    <div className={`${classes.team} ${isMobileView && classes.mobile}`}>
      {filterRow}
      <div>
        <div className={classes.topBar}>
          <div className={classes.score_card__total_points}>
            <span>Total Points</span>
            <b>{props.loading ? "-" : getTotalPoints()}</b>
            {refreshIcon}
          </div>
        </div>
        {pitch}
      </div>
    </div>
  );
};

export default Team;
