// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wrapper_wrapper__ZMGMD {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Wrapper/Wrapper.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,0BAA0B;IAC1B,iCAAiC;IACjC,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".wrapper {\r\n    margin: 0;\r\n    padding: 0;\r\n    display: grid;\r\n    grid-template-columns: 1fr;\r\n    grid-template-rows: auto 1fr auto;\r\n    min-height: 100vh;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Wrapper_wrapper__ZMGMD`
};
export default ___CSS_LOADER_EXPORT___;
