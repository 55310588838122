import classes from "./Header.module.css";

import logo from "../../../assets/img/logo.png";

const Header = () => {
  return (
    <div className={classes.header}>
      <img className={classes.logo} src={logo} alt="logo" />
    </div>
  );
};

export default Header;
