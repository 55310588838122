import { useState } from "react";
import classes from "./TeamPlayerCard.module.css";
import TeamPlayerInfoCard from "./TeamPlayerInfoCard";

const TeamPlayerCard = (props) => {
  const [playerCard, setPlayerCard] = useState(null);

  const player = props.player;
  let badge;
  if (player.is_captain || player.is_vice_captain) {
    badge = (
      <div className={classes.badge}>{player.is_captain ? "C" : "V"}</div>
    );
  }

  let photo = `https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace(
    ".jpg",
    ".png"
  )}`;

  const pointScorecard = player.fixture.started
    ? player.stats.stats.total_points *
      (player.multiplier < 1 ? 1 : player.multiplier)
    : `${player.opponent.short_name} (${player.opponent.is_home ? "H" : "A"})`;

  const showPlayerCard = (e) => {
    if (!playerCard){
      setPlayerCard(player);
    }
  };

  const closePlayerCard = () => {
    setPlayerCard(null);
  };

  return (
    <div className={classes.card} onClick={showPlayerCard}>
      <img
        src={photo}
        className={classes.pitchCardImage}
        alt={player.web_name}
      />
      <h2 className={classes.name}>{player.web_name}</h2>
      <p className={classes.points}>{pointScorecard}</p>
      <div
        className={classes.badges}
        captain={player.is_captain.toString()}
        vice-captain={player.is_vice_captain.toString()}
      >
        {badge}
      </div>
      {playerCard && (
        <TeamPlayerInfoCard player={playerCard} onClose={closePlayerCard} />
      )}
    </div>
  );
};

export default TeamPlayerCard;
