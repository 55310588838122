import React, { useEffect, useState } from "react";

import "./App.css";
import Footer from "./Components/Layout/Footer/Footer";
import Header from "./Components/Layout/Header/Header";
import Main from "./Components/Layout/Main/Main";
import Wrapper from "./Components/Layout/Wrapper/Wrapper";
import Team from "./Components/Team/Team";

const App = () => {
  const apiBase = document.location.hostname === "pl-fantasy.qvalento.com" ? "https://qvalento-node-proxy-xbotcni3yq-ew.a.run.app" : "http://localhost:8080";
  const [isLoading, setIsLoading] = useState(true);
  const [entry, setEntry] = useState(3877784);
  const [gameWeek, setGameWeek] = useState(null);
  const [gameWeeks, setGameWeeks] = useState([]);
  const [players, setPlayers] = useState([]);

  const getPlayers = () => {
    setIsLoading(true);
    fetch(`${apiBase}/api/v1/event/players?event=${gameWeek}&entry=${entry}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPlayers(data);
      });
  };

  useEffect(() => {
    fetch(`${apiBase}/api/v1/static`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGameWeek(data.events.filter((x) => x.is_current)[0].id);
        setGameWeeks(data.events);
      });
  }, [apiBase]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [players]);

  useEffect(() => {
    if (gameWeek) {
      getPlayers();
    }
  }, [entry, gameWeek]);

  const selectGameWeek = (value) => {
    setGameWeek(value);
  };

  const selectEntry = (value) => {
    setEntry(value);
  };

  return (
    <Wrapper>
      <Header />
      <Main>
        <Team
          entry={entry}
          gameWeek={gameWeek}
          playedGameWeeks={
            gameWeeks.length > 0 ? gameWeeks.find((x) => x.is_current).id : 1
          }
          players={players}
          loading={isLoading}
          onSelectGameWeek={selectGameWeek}
          onSelectEntry={selectEntry}
          onRefresh={getPlayers}
        />
      </Main>
      {/* <Footer /> */}
    </Wrapper>
  );
};

export default App;
