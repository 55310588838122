export const getEntries = () => {
  return [
    {
      label: "Linus Larsson",
      value: "3877784",
    },
    {
      label: "Niklas Torgersson",
      value: "3524009",
    },
    {
      label: "Daniel Torgersson",
      value: "2425857",
    },
    {
      label: "Jimmy Gullbrandsson",
      value: "1308333",
    },
  ];
};
