// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__qlEJZ {
    width: 100%;
    background: rgb(219, 179, 179);
}

.Footer_nav__B2zjo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.Footer_navItem__oyGRf {
    padding: 6px 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".footer {\r\n    width: 100%;\r\n    background: rgb(219, 179, 179);\r\n}\r\n\r\n.nav {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-transform: uppercase;\r\n}\r\n\r\n.navItem {\r\n    padding: 6px 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__qlEJZ`,
	"nav": `Footer_nav__B2zjo`,
	"navItem": `Footer_navItem__oyGRf`
};
export default ___CSS_LOADER_EXPORT___;
