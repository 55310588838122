import classes from "./TeamPlayerInfoCard.module.css";

const TeamPlayerInfoCard = (props) => {
  const player = props.player;
  console.log(player);
  const teams = {
    home: player.team.is_home ? player.team : player.opponent,
    away: player.team.is_home ? player.opponent : player.team,
  };
  console.log(teams);

  const closeCard = (e) => {
    if (!e.target.closest(`.${classes.modal}`)) {
      props.onClose();
    }
  };

  let photo = `https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace(
    ".jpg",
    ".png"
  )}`;

  const pointList = player.stats.explain[0].stats.map((x) => {
    return (
      <tr key={`${x.identifier}-label`}>
        <td className={classes.cell__label}>
          {x.identifier.replace("_", " ")}
        </td>
        <td className={classes.cell__value}>{x.value}</td>
        <td className={classes.cell__points}>{x.points}p</td>
      </tr>
    );
  });

  const kickoff = new Date(player.fixture.kickoff_time);
  const dateFormatter = new Intl.DateTimeFormat("sv", {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <div className={classes.infoCard}>
      <div className={classes.overlay} onClick={closeCard}>
        <div className={classes.modal}>
          <div className={classes.topBar}>
            <img
              src={photo}
              className={classes.topBar__profileImage}
              alt={player.web_name}
            />
            <div className={classes.topBar__content}>
              <h2 className={classes.topBar__name}>
                {player.first_name} {player.second_name}
              </h2>
              <div className={classes.topBar__club}>{player.team.name}</div>
              <div className={classes.topBar__points}>
                {player.stats.stats.total_points}p
              </div>
            </div>
          </div>
          <div className={classes.statBar}>
            <div className={classes.statBar__card}>
              <label>Form</label>
              <span>{player.aggregated.form}</span>
            </div>
            <div className={classes.statBar__card}>
              <label>Total points</label>
              <span>{player.aggregated.total_points}</span>
            </div>
            <div className={classes.statBar__card}>
              <label>Total bonus</label>
              <span>{player.aggregated.bonus}</span>
            </div>
            <div className={classes.statBar__card}>
              <label>Selected by</label>
              <span>{player.aggregated.selected_by_percent}%</span>
            </div>
          </div>
          <div className={classes.content}>
            <div
              className={`${classes.topBar__fixtureResult} ${
                player.fixture.started ? classes.showScore : ""
              }`}
            >
              {!player.fixture.started && (
                <div className={classes.topBar__fixtureResult__kickoff}>
                  {dateFormatter.format(kickoff)}
                </div>
              )}
              <div className={classes.container}>
                <div className={classes.topBar__fixtureResult__team}>
                  <img src={teams.home.badge} alt={teams.home.short_name} />
                  <span>{teams.home.short_name}</span>
                </div>
                <div className={classes.topBar__fixtureResult__result}>
                  <span>{player.fixture.team_h_score}</span>
                  <span>-</span>
                  <span>{player.fixture.team_a_score}</span>
                </div>
                <div className={classes.topBar__fixtureResult__team}>
                  <span>{teams.away.short_name}</span>
                  <img src={teams.away.badge} alt={teams.away.short_name} />
                </div>
              </div>
            </div>
            {player.fixture.started && (
              <div className={classes.stats}>
                <table cellSpacing="0">
                  <thead>
                    <tr>
                      <th className={classes.cell__label}>Statistic</th>
                      <th className={classes.cell__value}>Value</th>
                      <th className={classes.cell__points}>Points</th>
                    </tr>
                  </thead>
                  <tbody>{pointList}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPlayerInfoCard;
