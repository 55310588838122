import classes from "./MainLoading.module.css";

const MainLoading = () => {
  return (
    <div className={classes.mainLoading}>
      <div className={classes.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default MainLoading;
